export enum TrainingGroupComposition {
    FOR_MALE = "FOR_MALE",
    FOR_FEMALE = "FOR_FEMALE",
    UNISEX = "UNISEX"
}

export const TrainingGroupCompositionConfig: {
    [key in TrainingGroupComposition]: {
        filterLabel: string,
        displayName: string
    }
} = {
    FOR_MALE: {
        filterLabel: "Für Männer/Jungen",
        displayName: "Nur Männer/Jungen"
    },
    FOR_FEMALE: {
        filterLabel: "Für Frauen/Mädchen",
        displayName: "Nur Frauen/Mädchen"
    },
    UNISEX: {
        filterLabel: "Unisex",
        displayName: "Unisex"
    }
}