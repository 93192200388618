import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { CheckLg, ChevronLeft, XLg } from 'react-bootstrap-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { SingleTGResponse, fetchTrainingGroup } from '../../api/training-group/fetchTrainingGroup';
import { DetailView } from '../../components/DetailView';
import { FacilityInfo } from '../../components/FacilityInfo';
import { TrainingGroupCompositionConfig } from '../../types/TrainingGroupComposition';

interface Props {
    backendUrl: string
}

export const TGDetailView: React.FC<Props> = ({ backendUrl }) => {
    const [{ trainingGroup, club, facilities }, setData] = useState<SingleTGResponse>({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchTrainingGroup(backendUrl, id)
                .then(data => {
                    setData(data);
                    setLoading(false);
                })
                .catch(err => {
                    console.error(err);
                    setError(true);
                    setLoading(false);
                })
        }
    }, [id, backendUrl])

    return (
        <DetailView
            loading={loading}
            error={error}
            notFoundMsg={"Zu Ihrer Anfrage konnte keine Trainingsgruppe gefunden werden."}
            entity={trainingGroup}
        >
            {trainingGroup &&
                <>
                    <div className="d-flex align-items-center">
                        <ChevronLeft className="clickable-icon me-2 mt-2" onClick={() => navigate(-1)} />
                        <h4 className="m-0 text-primary">{trainingGroup.sport.name}</h4>
                    </div>
                    <hr className="my-4" />
                    <Row>
                        <Col md={8}>
                            <Row className="mb-3">
                                <h5 className="fs-3 text-primary">Verein</h5>
                                <div>
                                    {club?.name || trainingGroup.clubName}
                                </div>
                            </Row>
                            <Row className="mb-3 circumstances">
                                <h5 className="fs-3 text-primary">Gegebenheiten</h5>
                                <div>
                                    {trainingGroup.inclusiveGroup ? <CheckLg /> : <XLg />} Inklusivsport
                                </div>
                                <div>
                                    {trainingGroup.wheelchairAccessible ? <CheckLg /> : <XLg />} Rollstuhlgerecht
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <h5 className="fs-3 text-primary">Geschlecht</h5>
                                <div>
                                    {TrainingGroupCompositionConfig[trainingGroup.groupComposition].displayName}
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <h5 className="fs-3 text-primary">Altersgruppe</h5>
                                <div>
                                    {trainingGroup.ageGroup.from} bis {trainingGroup.ageGroup.to}
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <h5 className="fs-3 text-primary">Beschreibung</h5>
                                <div>
                                    {trainingGroup.description}
                                </div>
                            </Row>
                            <Row className="mb-3">
                                <h5 className="fs-3 text-primary">Kontakt</h5>
                                <div dangerouslySetInnerHTML={{ __html: trainingGroup.contactInformation.replace(/\n/g, "<br />") }}></div>
                            </Row>
                        </Col>
                        <Col md={4}>
                            {
                                facilities?.map((facility, index) =>
                                    <Row key={index}><FacilityInfo trainingGroup={trainingGroup} facility={facility} /></Row>)
                            }
                        </Col>
                    </Row>
                </>
            }
        </DetailView>
    )
}