const FilterDeleteOutline = (props: any) => {
    return (
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path 
                d="m14.73 20.83 2.85-2.83-2.85-2.83 1.42-1.41 2.85 2.81 2.8-2.81 1.42 1.41-2.81 2.83 2.81 2.83-1.42 1.41-2.8-2.84-2.85 2.84zm-1.73-.95c.04.3-.06.62-.29.83-.39.39-1.02.39-1.41 0l-4.01-4.01c-.23-.23-.33-.54-.29-.83v-5.12l-4.79-6.13c-.34-.43-.26-1.06.17-1.4.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22.43.34.51.97.17 1.4l-4.79 6.13zm-7.96-14.88 3.96 5.06v5.52l2 2v-7.53l3.96-5.05z"
            />
        </svg>
    );
}

export default FilterDeleteOutline;