import { addPadding } from "./addPadding";

export function msToTime(time: number) {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;

    let hours = Math.floor(time / hour % 24);
    let minutes = Math.floor(time / minute % 60);

    return addPadding(hours) + ':' + addPadding(minutes) + " Uhr";
}