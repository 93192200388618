import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { fetchClubs } from '../api/club/fetchClubs';
import { fetchTrainingGroups } from '../api/training-group/fetchTrainingGroups';
import { getGroupsOfTwo } from '../helpers/getGroupsOfTwo';
import { ClubCard } from '../pages/club/ClubCard';
import { ClubInputs } from './club/ClubInputs';
import { TGCard } from '../pages/training-group/TGCard';
import { TGInputs } from './trainingGroup/TGInputs';
import { Club } from '../types/club/Club';
import { SearchInput } from '../types/SearchInput';
import { TrainingGroup } from '../types/training-group/TrainingGroup';
import { Error } from './Error';
import { Spinner } from './Spinner';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_ERROR_MESSAGE } from '../types/ErrorMessages';
import { ClubSearchInput } from '../types/club/ClubSearchInput';
import { TrainingGroupSearchInput } from '../types/training-group/TGSearchInput';
import { fetchSports } from '../api/sport/fetchSports';

const initialQueryObject: QueryObject = {
    page: -1,
    size: 30,
}

type ReducerAction = {
    type: ActionType,
    payload?: QueryObject
}

type ActionType = "update" | "reset";

export type QueryObject = SearchInput & {
    page?: number,
    size?: number,
}

const reducer = (state: QueryObject, action: ReducerAction) => {
    switch (action.type) {
        case "update":
            return {
                ...state,
                ...action.payload
            }
        case "reset":
            return initialQueryObject;
        default:
            return state;
    }
}

interface Props {
    type: "TrainingGroup" | "Club"
    backendUrl: string
    runInitialSearch: boolean
    setFirstEnter: (value: boolean) => void
}

export const OverviewView: React.FC<Props> = ({ type, backendUrl, runInitialSearch, setFirstEnter }) => {
    const [sports, setSports] = useState([] as string[]);
    const [data, setData] = useState<(TrainingGroup | Club)[] | null>(null);
    const [error, setError] = useState("");
    const [query, dispatch] = useReducer(reducer, initialQueryObject);
    const [loading, setLoading] = useState(false);
    const [loadingSports, setLoadingSports] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        if (type !== "Club") {
            fetchSports(backendUrl, {})
            .then(data => { 
                setSports(data);
            })
        }
    }, [backendUrl, type])

    const resetState = () => {
        setError("");
        setData(null);
        dispatch({ type: "reset" });
    }

    const resetAndInitialFetch = async (input: SearchInput) => {
        resetState();
        dispatch({
            type: "update",
            payload: {
                ...input,
                page: 0,
            }
        });
    }

    const fetchMore = useCallback(() => {
        return new Promise(async (resolve, reject) => {
            let fetchedData: {
                data: (Club | TrainingGroup)[],
                nextPage?: number
            } = {
                data: []
            };
            try {
                if (type === "Club") {
                    const queryWithoutEmptyValues = removeEmptyClubSearchValues(query as ClubSearchInput);
                    const response = await fetchClubs(backendUrl, queryWithoutEmptyValues);
                    fetchedData.data = response.clubs;
                    fetchedData.nextPage = response.nextPage;
                } else if (type === "TrainingGroup") {
                    const queryWithoutEmptyValues = removeEmptyTGSearchValues(query as TrainingGroupSearchInput);
                    const response = await fetchTrainingGroups(backendUrl, queryWithoutEmptyValues);
                    fetchedData.data = response.trainingGroups;
                    fetchedData.nextPage = response.nextPage;
                }
                const existingData = data || [];
                const newData = [...existingData, ...fetchedData.data];
                setData(newData);
                dispatch({
                    type: "update",
                    payload: {
                        page: fetchedData.nextPage,
                    }
                });
                setLoading(false);
                resolve(newData);
            } catch (err) {
                console.log(error);
                setError(DEFAULT_ERROR_MESSAGE);
                dispatch({
                    type: "update",
                    payload: {
                        page: -1,
                    }
                });
                setLoading(false);
                reject(error);
            }
        })
    }, [backendUrl, data, error, query, type])


    useEffect(() => {
        if (query.page === 0) {
            //need loading to determine when to show the message to the user that no data was found
            setLoading(true);
            fetchMore();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query.page])

    const showDetail = (entity: TrainingGroup | Club) => {
        setFirstEnter(false);
        navigate(type === "Club" ? "/club/" + entity.id : entity.id);
    }

    return (
        <Container className="outer-container" fluid="xl">
            {type === "Club" ?
                <ClubInputs
                    className="mb-3"
                    sports={sports}
                    loadingSports={loadingSports}
                    fetchSports={(input) => {
                        setLoadingSports(true);
                        fetchSports(backendUrl, input)
                        .then(data => setSports(data))
                        .finally(() => setLoadingSports(false));
                    }}
                    fetchClubs={resetAndInitialFetch}
                    reset={resetState}
                    setError={setError}
                    runInitialSearch={runInitialSearch}
                />
                :
                <TGInputs
                    className="mb-3"
                    sports={sports}
                    fetchTrainingGroups={resetAndInitialFetch}
                    reset={resetState}
                    setError={setError}
                />
            }
            {error ?
                <Error message={error} />
                :
                // Only for initial page opening for club search without running the initial search and not currently loading 
                // display the hint to find your new sport club
                (type ==="Club" && !runInitialSearch && data === null && !loading) ? 
                    <div className="text-center mt-4">
                        <h5>Finde jetzt Deinen neuen Sportverein!</h5>
                    </div>
                : data?.length === 0 && !loading ?
                    <div className="text-center mt-4">
                        <h5>Zu Ihrer Anfrage konnten keine Ergebnisse gefunden werden.</h5>
                    </div>
                    :
                    <InfiniteScroll
                        next={fetchMore}
                        hasMore={query.page !== -1}
                        dataLength={data?.length || 0}
                        loader={<Spinner />}
                        style={{ height: "100%", overflow: "hidden", padding: "16px 0" }}
                        className="list-container"
                    >
                        {data &&
                            type === "Club" ?
                            getGroupsOfTwo<Club>(data as Club[]).map((group, index) => (
                                <Row key={index} className="two-entities-row">
                                    {group.map(entity => (
                                        <ClubCard key={entity.id} club={entity as Club} selectedSport={query.sportName} showClubDetail={showDetail} />
                                    ))}
                                </Row>
                            ))
                            :
                            data?.map(entity => (
                                <TGCard key={entity.id} trainingGroup={entity as TrainingGroup} showTGDetail={showDetail} />
                            ))
                        }
                    </InfiniteScroll>
            }
        </Container>
    )

}

const removeEmptyClubSearchValues = (values: QueryObject & ClubSearchInput) => {
    const newValues = {
        page: values.page,
        size: values.size
    } as QueryObject & ClubSearchInput;
    if (values.clubName) {
        newValues.clubName = values.clubName;
    }
    if (values.perimeter) {
        newValues.perimeter = values.perimeter;
    }
    if (values.postalCode) {
        newValues.postalCode = values.postalCode;
    }
    if (values.sportName) {
        newValues.sportName = values.sportName;
    }
    if (values.areaSearchType) {
        newValues.areaSearchType = values.areaSearchType;
    }
    return newValues;
}

const removeEmptyTGSearchValues = (values: QueryObject & TrainingGroupSearchInput) => {
    const newValues = {
        page: values.page,
        size: values.size,
        inclusiveGroup: values.inclusiveGroup,
        wheelchairAccessible: values.wheelchairAccessible
    } as QueryObject & TrainingGroupSearchInput;
    if (typeof values.age !== "undefined") {
        newValues.age = values.age;
    }
    if (typeof values.endTime !== "undefined") {
        newValues.endTime = values.endTime;
    }
    if (typeof values.startTime !== "undefined") {
        newValues.startTime = values.startTime;
    }
    if (values.perimeter) {
        newValues.perimeter = values.perimeter;
    }
    if (values.postalCode) {
        newValues.postalCode = values.postalCode;
    }
    if (values.sportName) {
        newValues.sportName = values.sportName;
    }
    if (values.weekdays && values.weekdays.length !== 0) {
        newValues.weekdays = values.weekdays;
    }
    if (values.groupComposition) {
        newValues.groupComposition = values.groupComposition;
    }
    return newValues;
}
