import { Club } from "../../types/club/Club";
import { Facility } from "../../types/Facility";
import { TrainingGroup } from "../../types/training-group/TrainingGroup";

export type SingleTGResponse = {
    trainingGroup?: TrainingGroup,
    club?: Club,
    facilities?: Facility[]
}

export const fetchTrainingGroup = async (backendUrl: string, id: string): Promise<SingleTGResponse> => {
    const response = await fetch(backendUrl + "/training-group/" + id);
    const data = await response.json();
    return data;
}