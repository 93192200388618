import { makeNonBreaking } from '../helpers/makeNonBreaking';
import { Club } from '../types/club/Club'

type Props = {
    club: Club
}

export const ContactInfo: React.FC<Props> = ({ club }) => {
    const { contact } = club;

    return (contact ? 
        <div>
            {contact.postalCode && contact.city ?
                <div className="table-row">
                    <div className="text-primary first">Ort:</div>
                    <div className="second">{contact.postalCode}&nbsp;{contact?.city}</div>
                </div> : <></>
            }
            {club.contactPerson ?
                <div className="table-row">
                    <div className="text-primary first">Kontaktperson / Kontaktinformation:</div>
                    <div className="second">{club.contactPerson}</div>
                </div> : <></>
            }
            {contact.telephone.length ?
                <div className="table-row">
                    <div className="text-primary first">Telefon:</div>
                    <div className="second">{contact?.telephone.map((telephone, index) => <div key={index}>
                            <a target="_blank" rel="noreferrer" href={"tel:" + telephone.number}>{makeNonBreaking(telephone.number)}</a>
                        </div>)}</div>
                </div> : <></>
            }
            {contact.email ?
                <div className="table-row">
                    <div className="text-primary first">E-Mail:</div>
                    <div className="second"><a target="_blank" rel="noreferrer" href={"mailto:" + contact.email}>{contact.email}</a></div>
                </div> : <></>
            }
            {club.homepage &&
                <div className="table-row">
                    <div className="text-primary first">Homepage:</div>
                    <div className="second"><a target="_blank" rel="noreferrer" href={club.homepage}>{club.homepage}</a></div>
                </div>
            }
        </div> : 
        <div>
            {club.homepage &&
                <div className="table-row">
                    <div className="text-primary first">Homepage:</div>
                    <div className="second"><a target="_blank" rel="noreferrer" href={club.homepage}>{club.homepage}</a></div>
                </div>
            }
        </div>
        
    )
}