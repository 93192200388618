export enum Weekday {
    MONDAY = "MONDAY",
    TUESDAY = "TUESDAY",
    WEDNESDAY = "WEDNESDAY",
    THURSDAY = "THURSDAY",
    FRIDAY = "FRIDAY",
    SATURDAY = "SATURDAY",
    SUNDAY = "SUNDAY"
}

export const WeekdayConfig: {
    [key in Weekday]: {
        translation: string,
        ordinal: number
    }
} = {
    MONDAY: {
        translation: "Montag",
        ordinal: 0
    },
    TUESDAY: {
        translation: "Dienstag",
        ordinal: 1
    },
    WEDNESDAY: {
        translation: "Mittwoch",
        ordinal: 2
    },
    THURSDAY: {
        translation: "Donnerstag",
        ordinal: 3
    },
    FRIDAY: {
        translation: "Freitag",
        ordinal: 4
    },
    SATURDAY: {
        translation: "Samstag",
        ordinal: 5
    },
    SUNDAY: {
        translation: "Sonntag",
        ordinal: 6
    }
}

export function getWeekdayByTranslation(translation: string) {
    return Object.keys(WeekdayConfig).find(key => WeekdayConfig[key as Weekday].translation === translation) as Weekday;
}