import React from 'react'
import { Button, Form, FormControlProps } from 'react-bootstrap';
import CloseX from './gfx/CloseX';

interface Props extends FormControlProps{
    setValue: (value: string) => void
}

export const FormInputField: React.FC<Props> = (props: Props) => {
    const { setValue, ...others} = props
    return (
        <>
            <Form.Control className="club-name-input form-input" 
                {...others}
                onChange={(event) => setValue(event.currentTarget.value)}
            />
            <Button className="inner-btn" style={{display: !props.value || props.value === '' ? 'none' : 'block'}} type="button" onClick={event => {
                setValue('');
            }}><CloseX /></Button>
        </>
    )
}