import React, { FormEvent, useEffect, useMemo, useReducer, useState } from 'react'
import { Button, Col, Collapse, Form, Row } from 'react-bootstrap'
import { TrainingGroupSearchInput } from '../../types/training-group/TGSearchInput'
import { getWeekdayByTranslation, Weekday, WeekdayConfig } from '../../types/Weekday';
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import { TimePicker } from '../TimePicker';
import { isBefore } from '../../helpers/isBefore';
import { isAfter } from '../../helpers/isAfter';
import { ageRegex } from '../../regex/ageGroupRegex';
import { getTranslationArray } from '../../helpers/getTranslatedList';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { isPostalCodeValid } from '../../helpers/regexValidity';
import { getNumberByConditionOrUndefined, getStringByConditionOrEmptyString } from '../../helpers/getNumberByConditionOrType';
import { TrainingGroupComposition, TrainingGroupCompositionConfig } from '../../types/TrainingGroupComposition';

const TWENTY_THREE_HOURS_AND_FOURTY_FIVE_MINUTES_IN_MILLISECONDS = 85500000;

const perimeters = [1, 2, 3, 4, 5, 10];

const booleanSelectValues = ["", "Ja", "Nein"];

type ReducerAction = {
    type: ActionType,
    payload?: TrainingGroupSearchInput
}

type ActionType = "update" | "reset";

const reducer = (state: TrainingGroupSearchInput, action: ReducerAction) => {
    switch (action.type) {
        case "update":
            return {
                ...state,
                ...action.payload
            }
        case "reset":
            return {};
        default:
            return state;
    }
}

interface Props {
    sports: string[]
    fetchTrainingGroups: (inputs: TrainingGroupSearchInput) => void
    reset: () => void
    className?: string
    setError: (error: string) => void
}

type SimpleSearchInput = {
    sportName?: string | null,
    postalCode?: string | null
}

type ExtendedSearchInput = TrainingGroupSearchInput;

export const TGInputs: React.FC<Props> = ({ sports, fetchTrainingGroups, reset, className, setError }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [input, dispatch] = useReducer(reducer, getInitialInputsObject(searchParams));
    const [extendedSearchOpen, setExtendedSearchOpen] = useState(searchParams.get("extendedSearch") === "true");

    //being passed to fetch function to differentiate between simple and extended search
    //if extended search options in the ui are open => extended query
    //else => simple query
    const simpleSearchQuery = useMemo(() => ({ sportName: input.sportName, postalCode: input.postalCode }) as SimpleSearchInput, [input.sportName, input.postalCode]);
    const extendedSearchQuery = useMemo(() => input, [input]);
    const fetchQuery = useMemo(() => extendedSearchOpen ? extendedSearchQuery : simpleSearchQuery, [extendedSearchOpen, simpleSearchQuery, extendedSearchQuery]);

    useEffect(() => {
        const weekdayDropdown = document.querySelector(".dropdown-toggle");
        weekdayDropdown?.classList.remove("btn");
        weekdayDropdown?.classList.remove("btn-light");
        weekdayDropdown?.classList.add("form-control");

        fetchTrainingGroups(fetchQuery);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const updateInput = (payload: TrainingGroupSearchInput) => {
        dispatch({
            type: "update",
            payload
        })
    }

    const validateAndSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (isFormValid()) {
            fetchTrainingGroups(fetchQuery);
            setSearchParams(convertToSearchParams(fetchQuery));
        } else {
            reset();
            updateError();
        }
    }

    const isFormValid = () => {
        let isValid = true;
        if (input.postalCode && !isPostalCodeValid(input.postalCode)) {
            isValid = false;
        }
        else if (extendedSearchOpen && input.perimeter && !(input.postalCode && isPostalCodeValid(input.postalCode))) {
            isValid = false;
        }
        return isValid;
    }

    const updateError = () => {
        if (input.postalCode && !isPostalCodeValid(input.postalCode)) {
            setError("Geben Sie eine 5-stellige Postleitzahl ein");
        }
        else if (extendedSearchOpen && input.perimeter && !(input.postalCode && isPostalCodeValid(input.postalCode))) {
            setError("Geben Sie eine 5-stellige Postleitzahl ein");
        } else {
            setError("");
        }
    }

    return (
        <Form onSubmit={validateAndSubmit} className={className || ""}>
            <Row>
                <Form.Group as={Col} md className="mb-2">
                    <Form.Label>Sportart</Form.Label>
                    <Form.Select onChange={event => updateInput({ sportName: event.currentTarget.value })} value={input.sportName || ""}>
                        <option value=""></option>
                        {sports.map((sport, index) => (
                            <option key={index}>{sport}</option>
                        ))}
                    </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md className="mb-2">
                    <Form.Label>Postleitzahl</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={event => {
                            const postalCode = event.currentTarget.value;
                            //if postal code is a number or empty
                            if (/^\d{0,5}?$/.test(postalCode)) {
                                updateInput({
                                    postalCode: postalCode
                                })
                            }
                        }}
                        value={input.postalCode || ""}
                    />
                </Form.Group>
                <Col md={"auto"} className="align-self-end mb-2 mt-3 mt-md-0">
                    <Row>
                        <Col xs={"auto"} className="align-self-end">
                            <Button className="form-button text-white" variant="primary" type="submit">Suchen</Button>
                        </Col>
                        <Col className="align-self-center">
                            <div
                                className="button-link text-primary"
                                onClick={() => setExtendedSearchOpen(!extendedSearchOpen)}
                                aria-controls="collapsable"
                                aria-expanded={extendedSearchOpen}
                            >
                                Erweiterte Suche
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Collapse in={extendedSearchOpen}>
                <Row id="collapsable" className="mt-2">
                    <Col lg={9}>
                        <Row>
                            <Col className="perimeter-weekday-outer-col">
                                <Row className="perimeter-weekday-inner-row flex-nowrap">
                                    <Form.Group as={Col} xs={"auto"} className="mb-2">
                                        <Form.Label>Umkreis</Form.Label>
                                        <Form.Select onChange={event => updateInput({ perimeter: parseInt(event.currentTarget.value) })} value={input.perimeter || ""}>
                                            <option value=""></option>
                                            {perimeters.map((perimeter, index) => (
                                                <option key={index} value={perimeter}>{perimeter} km</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} className="mb-2">
                                        <Form.Label>Wochentag</Form.Label>
                                        <DropdownMultiselect
                                            options={Object.values(WeekdayConfig).map(weekday => weekday.translation)}
                                            handleOnChange={(selectedItems: string[]) => updateInput({ weekdays: selectedItems.map(s => getWeekdayByTranslation(s)) })}
                                            placeholder="&#160;"
                                            selectDeselectLabel="Alles auswählen/löschen"
                                            data-id="weekday-dropdown"
                                            placeholderMultipleChecked={input.weekdays && getTranslationArray<Weekday>(input.weekdays, WeekdayConfig).slice(0, 2).join(", ") + "..."}
                                            showSelectToggle={false}
                                            selected={input.weekdays ? getTranslationArray<Weekday>(input.weekdays, WeekdayConfig) : []}
                                        />
                                    </Form.Group>
                                </Row>
                            </Col>
                            <Col md>
                                <Row>
                                    <Form.Group as={Col} className="mb-2">
                                        <Form.Label>Geschlecht</Form.Label>
                                        <Form.Select
                                            onChange={event => updateInput({ groupComposition: event.currentTarget.value ? event.currentTarget.value as TrainingGroupComposition : undefined })}
                                            value={input.groupComposition || ""}
                                        >
                                            <option value=""></option>
                                            {Object.values(TrainingGroupComposition).map(groupComposition => (
                                                <option key={groupComposition} value={groupComposition}>{TrainingGroupCompositionConfig[groupComposition].filterLabel}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Col xs={"auto"}>
                                        <Form.Label>Beginn zwischen</Form.Label>
                                        <Row className="align-items-center">
                                            <Form.Group as={Col} className="mb-2">
                                                <TimePicker
                                                    onChange={(time: number) => updateInput({
                                                        //if selected time is after "end" time then set it to end time, else set selected time
                                                        startTime: isAfter(time, input.endTime) ? input.endTime : time
                                                    })}
                                                    value={input.startTime || 0}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} className="mb-2 p-0 flex-grow-0">
                                                <Form.Label>-</Form.Label>
                                            </Form.Group>
                                            <Form.Group as={Col} className="mb-2">
                                                <TimePicker
                                                    onChange={(time: number) => updateInput({
                                                        //if selected time is before "start" time then set it to start time, else set selected time
                                                        endTime: isBefore(time, input.startTime) ? input.startTime : time
                                                    })}
                                                    value={input.endTime || TWENTY_THREE_HOURS_AND_FOURTY_FIVE_MINUTES_IN_MILLISECONDS}
                                                />
                                            </Form.Group>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col lg={3}>
                        <Row>
                            <Col sm={9}>
                                <Row>
                                    <Form.Group as={Col} xs={6} className="mb-2">
                                        <Form.Label>Inklusivsport</Form.Label>
                                        <Form.Select
                                            onChange={event => updateInput({ inclusiveGroup: getBooleanFromString(event.currentTarget.value) })}
                                            value={getStringFromBoolean(input.inclusiveGroup)}
                                        >
                                            {booleanSelectValues.map((val, index) => (
                                                <option key={index} value={val}>{val}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group as={Col} xs={6} className="mb-2">
                                        <Form.Label className="wheelchair-accessible-label">Rollstuhlgerecht</Form.Label>
                                        <Form.Select
                                            onChange={event => updateInput({ wheelchairAccessible: getBooleanFromString(event.currentTarget.value) })}
                                            value={getStringFromBoolean(input.wheelchairAccessible)}
                                        >
                                            {booleanSelectValues.map((val, index) => (
                                                <option key={index} value={val}>{val}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Row>
                            </Col>
                            <Form.Group as={Col} sm={3} className="mb-2">
                                <Form.Label>Alter</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={event => {
                                        const age = event.currentTarget.value;
                                        if (ageRegex.test(age)) {
                                            updateInput({
                                                age: parseInt(age)
                                            })
                                        }
                                    }}
                                    value={input.age || ""}
                                />
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>
            </Collapse >
        </Form >
    )
}

export function getInitialInputsObject(searchParams: URLSearchParams): TrainingGroupSearchInput {
    const sportName = searchParams.get("sportName") || "";
    let postalCode = searchParams.get("postalCode");
    if (postalCode && !isPostalCodeValid(postalCode)) postalCode = "";
    let perimeter = getNumberByConditionOrUndefined(searchParams.get("perimeter"), perimeter => perimeter >= 0);
    let weekdays = searchParams.has("weekdays") ? searchParams.getAll("weekdays").filter(weekday => Object.keys(Weekday).includes(weekday)) as Weekday[] : [];
    let startTime = getNumberByConditionOrUndefined(searchParams.get("startTime"), startTime => isValidTime(startTime));
    let endTime = getNumberByConditionOrUndefined(searchParams.get("endTime"), endTime => isValidTime(endTime));
    if (endTime && startTime) {
        if (startTime > endTime) {
            startTime = endTime;
        } else if (endTime < startTime) {
            endTime = startTime;
        }
    }
    let inclusiveGroup: string | boolean | null | undefined = searchParams.get("inclusiveGroup");
    if (inclusiveGroup) {
        inclusiveGroup = inclusiveGroup === "true";
    } else {
        inclusiveGroup = undefined;
    }
    let wheelchairAccessible: string | boolean | null | undefined = searchParams.get("wheelchairAccessible");
    if (wheelchairAccessible) {
        wheelchairAccessible = wheelchairAccessible === "true";
    } else {
        wheelchairAccessible = undefined;
    }
    let age = getNumberByConditionOrUndefined(searchParams.get("age"), age => age >= 0);
    const groupComposition = getStringByConditionOrEmptyString(searchParams.get("groupComposition"), groupComposition => groupComposition in TrainingGroupComposition);
    return {
        sportName,
        postalCode,
        perimeter,
        weekdays,
        startTime,
        endTime,
        inclusiveGroup,
        wheelchairAccessible,
        age,
        groupComposition: groupComposition ? groupComposition as TrainingGroupComposition : undefined
    }
}

//convert inputs to a valid URLSearchParamsInit Object: Record<String, String | String[]>
const convertToSearchParams = (input: ExtendedSearchInput | SimpleSearchInput) => {
    const URLSearchParamsInit: URLSearchParamsInit = {};
    if (input.sportName) URLSearchParamsInit.sportName = input.sportName;
    if (input.postalCode) URLSearchParamsInit.postalCode = input.postalCode;
    if (isExtendedSearchInput(input)) {
        const extendedSearchInput = input as ExtendedSearchInput;
        URLSearchParamsInit.extendedSearch = "true";
        if (extendedSearchInput.perimeter) URLSearchParamsInit.perimeter = extendedSearchInput.perimeter + "";
        if (extendedSearchInput.age) URLSearchParamsInit.age = extendedSearchInput.age + "";
        if (typeof extendedSearchInput.inclusiveGroup !== "undefined") URLSearchParamsInit.inclusiveGroup = extendedSearchInput.inclusiveGroup + "";
        if (typeof extendedSearchInput.wheelchairAccessible !== "undefined") URLSearchParamsInit.wheelchairAccessible = extendedSearchInput.wheelchairAccessible + "";
        if (extendedSearchInput.startTime) URLSearchParamsInit.startTime = extendedSearchInput.startTime + "";
        if (extendedSearchInput.endTime) URLSearchParamsInit.endTime = extendedSearchInput.endTime + "";
        if (extendedSearchInput.weekdays) URLSearchParamsInit.weekdays = extendedSearchInput.weekdays.map(weekday => Weekday[weekday]);
        if (extendedSearchInput.groupComposition) URLSearchParamsInit.groupComposition = extendedSearchInput.groupComposition;
    }
    return URLSearchParamsInit;
}

function isExtendedSearchInput(input: TrainingGroupSearchInput | SimpleSearchInput) {
    //if weekdays exists in input, that means that this is an extended search input
    return "weekdays" in input;
}

function isValidTime(time: number) {
    return time >= 0 && time <= TWENTY_THREE_HOURS_AND_FOURTY_FIVE_MINUTES_IN_MILLISECONDS;
}

function getStringFromBoolean(bool: boolean | undefined): string | number | readonly string[] | undefined {
    if (typeof bool === "undefined") return "";
    return bool ? "Ja" : "Nein";
}

function getBooleanFromString(str: string) {
    if (!str) return undefined;
    if (str === "Ja") return true;
    else return false;
}