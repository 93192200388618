import React from 'react'
import { getReadableTimestampString } from '../../types/Appointment'
import { Season, SeasonConfig } from '../../types/Season'
import { WeekdayConfig } from '../../types/Weekday'
import { TrainingGroup, filterAndSortAppointments } from '../../types/training-group/TrainingGroup'

interface Props {
  season: Season
  info: TrainingGroup
  className?: string
}

export const TGAppointmentInfoView: React.FC<Props> = ({ season, info, className }) => {
  const appointments = filterAndSortAppointments(info.appointments, season);
  if (!appointments.length) return (<></>);

  return (
    <div className={className}>
      <h5 className="fs-4 text-primary">{SeasonConfig[season].translation}</h5>
      {appointments.map((appointment, index) => (
        <div key={index}>
          <span style={{ display: "inline-block", width: 100 }}>{WeekdayConfig[appointment.weekday].translation}</span>{getReadableTimestampString(appointment)}
        </div>
      ))}
    </div>
  )
}