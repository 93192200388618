import { TrainingGroupSearchInput } from "../../types/training-group/TGSearchInput";
import { TrainingGroup } from "../../types/training-group/TrainingGroup";

type MultiTGResponse = {
    trainingGroups: TrainingGroup[],
    nextPage: number
}

export const fetchTrainingGroups = async (backendUrl: string, body: TrainingGroupSearchInput): Promise<MultiTGResponse> => {
    const response = await fetch(backendUrl + "/training-group/", {
        method: "POST",
        headers: {
            "content-type": "application/json"
        },
        body: JSON.stringify(body)
    });
    const data = await response.json();
    return data;
}