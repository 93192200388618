import { safelyParseInt } from "./safelyParseInt";

//used for url params that could be undefined
export function getNumberByConditionOrUndefined(value: string | null, condition: (parsedInt: number) => boolean) {
    const parsedInt = safelyParseInt(value);
    if (!parsedInt) return undefined;
    return condition(parsedInt) ? parsedInt : undefined;
}

//used for url params that could be undefined
export function getNumberByConditionOrEmptyString(value: string | null, condition: (parsedInt: number) => boolean) {
    const parsedInt = safelyParseInt(value);
    if (!parsedInt) return "";
    return condition(parsedInt) ? parsedInt : "";
}

//used for url params that could be undefined
export function getStringByConditionOrEmptyString(value: string | null, condition: (str: string) => boolean) {
    if (!value) return "";
    return condition(value) ? value : "";
}
