import { createRoot } from 'react-dom/client';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { App, AppProps } from './App';

interface IndexProps extends AppProps {
  containerId: string
}

export const renderClubSearch = (props: IndexProps) => {
  const contentDiv = document.getElementById(props.containerId);
  contentDiv?.classList.add("verminext-clubsearch-root");
  const root = createRoot(contentDiv!);
  root.render(
    <App type={'Club'} {...props}/>
  )
}

export const renderTGSearch = (props: IndexProps) => {
  const contentDiv = document.getElementById(props.containerId);
  contentDiv?.classList.add("verminext-clubsearch-root");
  const root = createRoot(contentDiv!);
  root.render(
    <App type={'TrainingGroup'} {...props}/>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
