export enum FacilityType {
    GYM = "GYM",
    SPORTS_FIELD = "SPORTS_FIELD",
    SWIMMING_HALL = "SWIMMING_HALL",
    SWIMMING_POOL = "SWIMMING_POOL",
    OTHER = "OTHER",
    EXERCISE_ROOMS = "EXERCISE_ROOMS"
}

export const FacilityTypeConfig: {
    [key in FacilityType]: {
        translation: string,
    }
} = {
    GYM: {
        translation: "Sporthalle"
    },
    SPORTS_FIELD: {
        translation: "Sportplatz"
    },
    SWIMMING_HALL: {
        translation: "Schwimmhalle"
    },
    SWIMMING_POOL: {
        translation: "Schwimmbad"
    },
    EXERCISE_ROOMS: {
        translation: "Sport- und Bewegungsräume"
    },
    OTHER: {
        translation: "Sonstiges"
    }
}