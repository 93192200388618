const GoogleMapsLogo = (props: any) => {
    return (
        <svg width={20} height={20} viewBox="0 0 66.246 67.248" {...props}>
            <g transform="translate(-172.531 -218.455) scale(.98012)">
                <rect
                    width={60.099}
                    height={60.099}
                    x={176.031}
                    y={231.399}
                    rx={5.238}
                    ry={5.238}
                    style={{
                        opacity: 1,
                        fill: "#34a668",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: 1.78170907,
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeMiterlimit: 4,
                        strokeDasharray: "none",
                        strokeDashoffset: 0,
                        strokeOpacity: 1,
                        paintOrder: "markers stroke fill",
                    }}
                />
                <path
                    d="m206.477 260.9-28.987 28.987a5.218 5.218 0 0 0 3.78 1.61h49.621c1.694 0 3.19-.798 4.146-2.037z"
                    style={{
                        opacity: 1,
                        fill: "#5c88c5",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: ".26458335px",
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                />
                <path
                    d="M226.742 222.988c-9.266 0-16.777 7.17-16.777 16.014.007 2.762.663 5.474 2.093 7.875.43.703.83 1.408 1.19 2.107.333.502.65 1.005.95 1.508.343.477.673.957.988 1.44 1.31 1.769 2.5 3.502 3.637 5.168.793 1.275 1.683 2.64 2.466 3.99 2.363 4.094 4.007 8.092 4.6 13.914v.012c.182.412.516.666.879.667.403-.001.768-.314.93-.799.603-5.756 2.238-9.729 4.585-13.794.782-1.35 1.673-2.715 2.465-3.99 1.137-1.666 2.328-3.4 3.638-5.169.315-.482.645-.962.988-1.439.3-.503.617-1.006.95-1.508.359-.7.76-1.404 1.19-2.107 1.426-2.402 2-5.114 2.004-7.875 0-8.844-7.511-16.014-16.776-16.014z"
                    style={{
                        opacity: 1,
                        fill: "#dd4b3e",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: 3.13462877,
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeMiterlimit: 4,
                        strokeDasharray: "none",
                        strokeDashoffset: 0,
                        strokeOpacity: 1,
                        paintOrder: "markers stroke fill",
                    }}
                />
                <ellipse
                    cx={226.742}
                    cy={239.002}
                    rx={5.828}
                    ry={5.564}
                    style={{
                        opacity: 1,
                        fill: "#802d27",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: 1.08898437,
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeMiterlimit: 4,
                        strokeDasharray: "none",
                        strokeDashoffset: 0,
                        strokeOpacity: 1,
                        paintOrder: "markers stroke fill",
                    }}
                />
                <path
                    d="M190.301 237.283c-4.67 0-8.457 3.853-8.457 8.606s3.786 8.607 8.457 8.607c3.043 0 4.806-.958 6.337-2.516 1.53-1.557 2.087-3.913 2.087-6.29 0-.362-.023-.722-.064-1.079h-8.257v3.043h4.85c-.197.759-.531 1.45-1.058 1.986-.942.958-2.028 1.548-3.901 1.548-2.876 0-5.208-2.372-5.208-5.299 0-2.926 2.332-5.299 5.208-5.299 1.399 0 2.618.407 3.584 1.293l2.381-2.38c0-.002-.003-.004-.004-.005-1.588-1.524-3.62-2.215-5.955-2.215zm4.43 5.66.003.006v-.003z"
                    style={{
                        opacity: 1,
                        fill: "#fff",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: 1.80578804,
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeMiterlimit: 4,
                        strokeDasharray: "none",
                        strokeDashoffset: 0,
                        strokeOpacity: 1,
                        paintOrder: "markers stroke fill",
                    }}
                />
                <path
                    d="m215.184 251.929-7.98 7.979 28.477 28.475a5.233 5.233 0 0 0 .449-2.123v-31.165c-.469.675-.934 1.349-1.382 2.005-.792 1.275-1.682 2.64-2.465 3.99-2.347 4.065-3.982 8.038-4.585 13.794-.162.485-.527.798-.93.799-.363-.001-.697-.255-.879-.667v-.012c-.593-5.822-2.237-9.82-4.6-13.914-.783-1.35-1.673-2.715-2.466-3.99-1.137-1.666-2.327-3.4-3.637-5.169l-.002-.003z"
                    style={{
                        opacity: 1,
                        fill: "#c3c3c3",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: ".26458335px",
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity: 1,
                    }}
                />
                <path
                    d="m212.983 248.495-36.952 36.953v.812a5.227 5.227 0 0 0 5.238 5.238h1.015l35.666-35.666a136.275 136.275 0 0 0-2.764-3.9 37.575 37.575 0 0 0-.989-1.44 35.127 35.127 0 0 0-.95-1.508c-.083-.162-.176-.326-.264-.489z"
                    style={{
                        opacity: 1,
                        fill: "#fddc4f",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: 1.38578618,
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeMiterlimit: 4,
                        strokeDasharray: "none",
                        strokeDashoffset: 0,
                        strokeOpacity: 1,
                        paintOrder: "markers stroke fill",
                    }}
                />
                <path
                    d="m211.998 261.083-6.152 6.151 24.264 24.264h.781a5.227 5.227 0 0 0 5.239-5.238v-1.045z"
                    style={{
                        opacity: 1,
                        fill: "#fff",
                        fillOpacity: 1,
                        stroke: "none",
                        strokeWidth: 1.38578618,
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeMiterlimit: 4,
                        strokeDasharray: "none",
                        strokeDashoffset: 0,
                        strokeOpacity: 1,
                        paintOrder: "markers stroke fill",
                    }}
                />
            </g>
        </svg>
    );
}

export default GoogleMapsLogo;