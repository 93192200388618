export function getGroupsOfTwo<T>(arr: T[]) {
    return arr.reduce((newArr, obj, index) => {
        if (index % 2 === 0) {
            //push new array
            newArr.push([obj]);
        } else {
            //get previously pushed array and push value
            newArr[newArr.length - 1].push(obj);
        }
        return newArr;
    }, [] as T[][])
}