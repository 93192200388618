export enum Season {
    WINTER = "WINTER",
    SUMMER = "SUMMER",
    WHOLE_YEAR = "WHOLE_YEAR"
}

export const SeasonConfig: {
    [key in Season]: {
        translation: string,
        ordinal: number
    }
} = {
    WHOLE_YEAR: {
        translation: "Ganzjährig",
        ordinal: 0
    },
    SUMMER: {
        translation: "Sommer",
        ordinal: 1
    },
    WINTER: {
        translation: "Winter",
        ordinal: 2
    },
}