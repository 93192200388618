import React from 'react'
import { Spinner as BSSpinner } from "react-bootstrap";

export const Spinner = () => {
    return (
        <div className="my-3 text-center">
            <BSSpinner animation="border">
                <span className="visually-hidden">Lädt...</span>
            </BSSpinner>
        </div>
    )
}