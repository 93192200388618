import React, { ReactNode } from 'react'
import { Container } from 'react-bootstrap'
import { Club } from '../types/club/Club'
import { TrainingGroup } from '../types/training-group/TrainingGroup'
import { Error } from './Error'
import { Spinner } from './Spinner'

interface Props {
    loading: boolean
    error: boolean
    entity?: Club | TrainingGroup
    notFoundMsg: string
    children: ReactNode
}

export const DetailView: React.FC<Props> = ({ loading, error, entity, children, notFoundMsg }) => {
    return (
        <Container fluid="xl" className="detail border rounded p-4">
            {loading ?
                <Spinner />
                :
                error ?
                    <Error />
                    :
                    entity ?
                        children
                        :
                        <Error message={notFoundMsg} />
            }
        </Container>

    )
}