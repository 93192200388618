import { msToTime } from "../helpers/msToTime";
import { Season } from "./Season";
import { Weekday, WeekdayConfig } from "./Weekday"

export type Appointment = {
    facilityId: string,
    location: Location,
    postalCode: string
    weekday: Weekday,
    startTime: number,
    season: keyof typeof Season,
}

export function getReadableString(appointment: Appointment) {
    return WeekdayConfig[appointment.weekday].translation + " " + getReadableTimestampString(appointment);
}

export function getReadableTimestampString(appointment: Appointment) {
    return msToTime(appointment.startTime);
}