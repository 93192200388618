import { SportSearchInput } from "../../types/sport/SportSearchInput";

export const fetchSports = async (backendUrl: string, body: SportSearchInput): Promise<string[]> => {
    const urlParams = [];
    if (body?.postalCode) {
        urlParams.push("postalCode=" + encodeURIComponent(body.postalCode));
    }
    if (body?.perimeter) {
        urlParams.push("perimeter=" + encodeURIComponent(body.perimeter));
    }
    const urlParamsString = urlParams.length > 0 ? '?' + urlParams.join('&') : "";
    const response = await fetch(backendUrl + "/sport/" + urlParamsString);
    try {
        const data = await response.json();
        return data;
    } catch (err) {
        console.log(err);
    }
    return [];
}