import React from 'react'
import { getGoogleMapsLink } from '../helpers/getGoogleMapsLink'
import { Appointment, getReadableTimestampString } from '../types/Appointment'
import { Facility } from '../types/Facility'
import { FacilityTypeConfig } from '../types/FacilityType'
import { Season, SeasonConfig } from '../types/Season'
import { TrainingGroup, filterAndSortAppointmentsByFacility } from '../types/training-group/TrainingGroup'
import { WeekdayConfig } from '../types/Weekday'

interface Props {
    trainingGroup: TrainingGroup
    facility: Facility
}

export const FacilityInfo: React.FC<Props> = ({ trainingGroup, facility }) => {
    // do not show non facility block
    if (!facility && !trainingGroup.appointments.find(a => !a.facilityId)) return <></>;

    const wholeAppointments = filterAndSortAppointmentsByFacility(trainingGroup.appointments, Season.WHOLE_YEAR, facility);
    const summerAppointments = filterAndSortAppointmentsByFacility(trainingGroup.appointments, Season.SUMMER, facility);
    const winterAppointments = filterAndSortAppointmentsByFacility(trainingGroup.appointments, Season.WINTER, facility);

    // when there is a facility set there will always be at least one appointment 
    return (
        <div className="mb-5">
            <div className="mb-3">
                <h5 className="fs-3 text-primary text-decoration-underline">{facility.name}</h5>
                <div>
                    {FacilityTypeConfig[facility.type].translation}
                </div>
                <div>
                    {facility.contact.street}
                </div>
                <div>
                    {facility.contact.postalCode}&nbsp;{facility.contact.city}
                </div>
                <div>{getGoogleMapsLink(facility.contact)}</div>
            </div>
            {wholeAppointments && getAppointmentsDivs(wholeAppointments, Season.WHOLE_YEAR)}
            {summerAppointments && getAppointmentsDivs(summerAppointments, Season.SUMMER)}
            {winterAppointments && getAppointmentsDivs(winterAppointments, Season.WINTER)}
        </div>
    )
}

function getAppointmentsDivs(appointments: Appointment[], season: Season) {
    if (!appointments.length) return (<></>);
    return (<div className="ms-2">
        <h6>
            {SeasonConfig[season].translation}
        </h6>
        <div className="ms-2">
            {appointments.map((appointment, index) => (
                <div key={index}>
                    <span style={{ display: "inline-block", width: 100 }}>{WeekdayConfig[appointment.weekday].translation}</span>{getReadableTimestampString(appointment)}
                </div>
            ))}
        </div>
    </div>);
}