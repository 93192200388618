import React from 'react'
import { DEFAULT_ERROR_MESSAGE } from '../types/ErrorMessages'

interface Props {
    message?: string
}

export const Error: React.FC<Props> = ({ message }) => {
    return (
        <h5 className="text-danger error-text error-h5" style={{ textAlign: "center", marginTop: "16px" }}>
            {message || DEFAULT_ERROR_MESSAGE}
        </h5>
    )
}