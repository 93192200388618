import React from 'react'
import BSTimePicker from 'react-bootstrap-time-picker';

interface Props {
    onChange: (time: number) => void
    value?: number
    step?: number
    start?: string
    format?: number
}

export const TimePicker: React.FC<Props> = ({ onChange, value, step, start, format }) => {
    return (
        /* time is in seconds */
        <BSTimePicker
            start={start || "00:00"}
            step={step || 15}
            value={value ? value / 1000 : 0}
            onChange={(time: number) => onChange(time * 1000)}
            format={format || 24}
        />
    )
}