import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import { Club } from '../../types/club/Club'
import { ContactInfo } from '../../components/ContactInfo'

interface Props {
    club: Club
    showClubDetail: (club: Club) => void
    className?: string
    selectedSport?: string | null
}

export const ClubCard: React.FC<Props> = ({ club, showClubDetail, className, selectedSport }) => {
    return (
        <Col lg={6} key={club.name} onClick={() => showClubDetail(club)} className={"card-container mb-4" + (className || "")}>
            <Card className="club-card">
                <Card.Body className="club-card-body">
                    <Row className="club-card-body-row">
                        <Col>
                            <Card.Title className="mb-4 club-card-title text-primary">
                                {club.name}
                            </Card.Title>
                            {(club.homepage || club.contact || club.sports.length) ? <Row className="club-card-row">
                                {(club.contact || club.homepage) ? <div className="club-card-column club-contact mb-3">
                                    <ContactInfo club={club}></ContactInfo>
                                </div> : <></> }
                                {club.sports.length ?
                                    <div className="club-card-column mb-3">
                                        <div className="table-row">
                                            <div className="first text-primary">Sportarten:</div>
                                            <div className="second">{getSports(club.sports, selectedSport)}</div>
                                        </div>
                                    </div>: <></>
                                }
                            </Row> : <></>}
                        </Col>
                        <Col xs={1} className="align-self-center">
                            <ChevronRight />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}

const getSports = (sports: string[], selectedSport?: string | null) => {
    //only need first 3 items
    const newSports = sports.slice(0, 3);
    if (selectedSport) {
        if (newSports.includes(selectedSport)) {
            //remove selected sport from array
            newSports.splice(newSports.indexOf(selectedSport), 1);
        } else {
            //remove last element
            newSports.pop();
        }
        newSports.unshift(selectedSport);
    }

    if (sports.length > 3) {
        sports = sports.slice(0, 3);
        sports.push("...");
    }
    return <div className="sport">{sports.join(", ")}</div>;
}