import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { ChevronRight } from 'react-bootstrap-icons'
import { Season } from '../../types/Season'
import { TrainingGroup } from '../../types/training-group/TrainingGroup'
import { TGAppointmentInfoView } from './TGSeasonInfoView'
import { TrainingGroupCompositionConfig } from '../../types/TrainingGroupComposition'

interface Props {
    trainingGroup: TrainingGroup
    showTGDetail: (trainingGroup: TrainingGroup) => void
}

export const TGCard: React.FC<Props> = ({ trainingGroup, showTGDetail }) => {
    return (
        <Col className="mb-4 card-container">
            <Card className="mx-auto card" key={trainingGroup.id} onClick={() => showTGDetail(trainingGroup)}>
                <Card.Body>
                    <Row>
                        <Col>
                            <Card.Title className="mb-4 text-primary">
                                {trainingGroup.sport.name}
                            </Card.Title>
                            <Row>
                                <Col>
                                    <div className="mb-3">
                                        <h5 className="fs-4 text-primary">Verein</h5>
                                        <div>{trainingGroup.clubName}</div>
                                    </div>
                                    <div className="mb-3">
                                        <h5 className="fs-4 text-primary">Altersgruppe</h5>
                                        <div>{trainingGroup.ageGroup.from} bis {trainingGroup.ageGroup.to}</div>
                                    </div>
                                    <div className="mb-3">
                                        <h5 className="fs-4 text-primary">Geschlecht</h5>
                                        <div>{TrainingGroupCompositionConfig[trainingGroup.groupComposition].displayName}</div>
                                    </div>
                                </Col>
                                <Col sm>
                                    <TGAppointmentInfoView className="mb-3" info={trainingGroup} season={Season.WHOLE_YEAR} />
                                    <TGAppointmentInfoView className="mb-3" info={trainingGroup} season={Season.SUMMER} />
                                    <TGAppointmentInfoView className="mb-3" info={trainingGroup} season={Season.WINTER} />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={1} className="align-self-center">
                            <ChevronRight />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Col>
    )
}