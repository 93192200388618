import { Club } from "../../types/club/Club";
import { ClubSearchInput } from "../../types/club/ClubSearchInput";
import { SportAssociation } from "./SportAssociation";

type MultiClubResponse = {
    clubs: Club[],
    nextPage: number
}

export const fetchClubs = async (backendUrl: string, body: ClubSearchInput): Promise<MultiClubResponse> => {
    const response = await fetch(backendUrl + "/club/", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-Sport-Association": SportAssociation.LSB
        },
        body: JSON.stringify(body)
    });
    const data = await response.json();
    return data;
}