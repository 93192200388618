import { AgeGroup } from "../AgeGroup"
import { Appointment, getReadableString as getReadableAppointmentString } from "../Appointment"
import { Facility } from "../Facility"
import { TrainingGroupComposition } from "../TrainingGroupComposition"
import { Season } from "../Season"
import { SportLink } from "../SportLink"
import { WeekdayConfig } from "../Weekday"

export type TrainingGroup = {
    id: string,
    clubName: string,
    sport: SportLink,
    ageGroup: AgeGroup,
    appointments: Appointment[],
    inclusiveGroup: boolean,
    wheelchairAccessible: boolean,
    contactInformation: string,
    description: string,
    groupComposition: TrainingGroupComposition
}

export function getReadableString(info: TrainingGroup) {
    return info.appointments
        .map(appointment => getReadableAppointmentString(appointment))
        .join(", ");
}

export function filterAndSortAppointments(appointments: Appointment[], season: Season): Appointment[] {
    return appointments
        .filter(a => a.season === season)
        .sort((a, b) => {
            const weekdayCompare = WeekdayConfig[a.weekday].ordinal - WeekdayConfig[b.weekday].ordinal;
            return weekdayCompare !== 0 ? weekdayCompare : a.startTime - b.startTime;
        });
}

export function filterAndSortAppointmentsByFacility(appointments: Appointment[], season: Season, facility: Facility): Appointment[] {
    return filterAndSortAppointments(appointments
        .filter(a => a.facilityId === facility?.id), season);
}