import React, { useMemo, useState, CSSProperties, useRef } from 'react'
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';
import { getNumberByConditionOrEmptyString, getStringByConditionOrEmptyString } from '../../helpers/getNumberByConditionOrType';
import { isPostalCodeValid, isValidForRegex } from '../../helpers/regexValidity';
import { ClubSearchInput } from '../../types/club/ClubSearchInput';
import { useEffectOnce, useUpdateEffect, useIsFirstRender } from 'usehooks-ts';
import FilterDeleteOutline from '../gfx/FilterRemoveOutline';
import ReactSelect, { ClearIndicatorProps } from 'react-select';
import Creatable from 'react-select/creatable';
import { CSSObject } from '@emotion/serialize';
import CloseX from '../gfx/CloseX';
import { FormInputField } from '../FormInputField';
import { ReducedSearchInput } from '../../types/SearchInput';

interface Props {
    sports: string[]
    fetchClubs: (inputs: ClubSearchInput) => void
    fetchSports: (inputs: ReducedSearchInput) => void
    loadingSports: boolean;
    reset: () => void
    className?: string
    setError: (error: string) => void
    runInitialSearch: boolean;
}

const perimeters = [1, 2, 3, 4, 5, 10, 20, 30];
const perimeterOptions = [{ value: "county" as number | "county", label: "Gesamter Ort/Landkreis" }];
perimeterOptions.push(...perimeters.map((value) => { return { value: value as number | "county", label: value + " km" } }));


const ClearIndicator = (props: ClearIndicatorProps<any, false>) => {
    const {
        children = <CloseX />,
        getStyles,
        innerProps: { ref, ...restInnerProps },
    } = props;
    return (
        <div
            {...restInnerProps}
            ref={ref}
            style={getStyles('clearIndicator', props) as CSSProperties}
        >
            <div style={{ padding: '0px 5px' }}>{children}</div>
        </div>
    );
};

const ClearIndicatorStyles = (
    base: CSSObject,
    state: ClearIndicatorProps<any>
): CSSObject => ({
    ...base,
    cursor: 'pointer',
    fill: "#D42929",
    paddingTop: '4px'
});

export const ClubInputs: React.FC<Props> = ({ sports, fetchClubs, fetchSports, loadingSports, reset, className, setError, runInitialSearch }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [club, setClub] = useState(searchParams.get("clubName") || "");
    const [sport, setSport] = useState(getStringByConditionOrEmptyString(searchParams.get("sportName"), sportName => isValidForRegex(sportName, /^[A-zÀ-ü0-9.()-_ ]+$/)));
    const [postalCode, setPostalCode] = useState(getStringByConditionOrEmptyString(searchParams.get("postalCode"), postalCode => isPostalCodeValid(postalCode)));
    const [perimeter, setPerimeter] = useState((): number | "county" | "" => {
        let areaSearchType = searchParams.get("areaSearchType");
        if (areaSearchType === "COUNTY") {
            return "county";
        }
        return getNumberByConditionOrEmptyString(searchParams.get("perimeter"), perimeter => perimeters.includes(perimeter));
    });
    const isFirstRender = useIsFirstRender();
    const fetchQuery = useMemo(() => ({
        clubName: club,
        sportName: sport,
        postalCode,
        perimeter: perimeter && perimeter !== "county" && postalCode && postalCode.length === 5 ? perimeter : undefined,
        areaSearchType: perimeter ? (perimeter !== "county" ? "PERIMETER" : "COUNTY") : undefined
    }) as ClubSearchInput, [club, sport, postalCode, perimeter]);

    const btnContainerRef = useRef(null);

    useEffectOnce(() => {
        // run the initial search on firstRender if runInitalSearch is on
        if ((isFirstRender && runInitialSearch) ||
            // run the inital search if query parameters are submitted too
            (club || sport || postalCode || perimeter)) {
            validateAndSubmit();
        }
        sportsListNeedUpdate(postalCode, perimeter);
    });

    // check that the current postal code value seems to be valid before trying an update on postalcode change
    useUpdateEffect(() => {
        if (!postalCode || postalCode?.length === 5 || postalCode?.length === 0) {
            sportsListNeedUpdate(postalCode, perimeter);
            validateAndSubmit();
        }
    }, [postalCode])

    // do not update on every button input to avoid useless traffic instead wait 250ms after the last user input to run the update call
    // 250ms should seem smooth enough to the enduser and should long enough to not update every single input
    useUpdateEffect(() => {
        const timer = setTimeout(() => {
            console.log('Timeout called!');
            validateAndSubmit();
        }, 250);
        return () => clearTimeout(timer);
    }, [club])

    // run an update on sport select field input
    useUpdateEffect(() => {
        validateAndSubmit();
    }, [sport]);

    // run an update on perimeter select field input
    useUpdateEffect(() => {
        sportsListNeedUpdate(postalCode, perimeter);
        validateAndSubmit();
    }, [perimeter]);

    const validateAndSubmit = () => {
        if (isFormValid()) {
            fetchClubs(fetchQuery);
            setSearchParams(convertToSearchParams(fetchQuery));
        } else {
            reset();
            updateError();
        }
    }

    const sportsListNeedUpdate = (postalCode: string, perimeter: string | number) => {
        if (isFormValid()) {
            let perimeterValue = null;
            if (perimeter && postalCode) {
                perimeterValue = Number(perimeter);
            }
            fetchSports({
                postalCode: postalCode ?? "",
                perimeter: perimeterValue
            });
        }
    }

    const isFormValid = () => {
        let isValid = true;
        if (postalCode && !isPostalCodeValid(postalCode)) {
            isValid = false;
        }
        return isValid;
    }

    const updateError = () => {
        //if perimeter is given but postal code doesn't have a length of 5
        if (!isPostalCodeValid(postalCode)) {
            setError("Geben Sie eine 5-stellige Postleitzahl ein");
        }
    }

    return (
        <Form className={className + " club-form form"} onSubmit={(event) => {
            event.preventDefault();
            validateAndSubmit();
        }}>
            <Row className="input-row">
                <Form.Group as={Col} className="club-name-div form-group mb-2" sm={6} lg={3} >
                    <Form.Label className="club-name-label form-label">Verein</Form.Label>
                    <FormInputField className="club-name-input form-input"
                        style={{ paddingRight: "45px" }}
                        type="text"
                        setValue={setClub}
                        value={club}
                    />
                </Form.Group>
                <Form.Group as={Col} className="club-sport-div form-group mb-2" sm={6} lg={3}>
                    <Form.Label className="club-sport-label form-label">Sportart</Form.Label>
                    <Creatable className="club-sport-select select-font-size"
                        isClearable={true}
                        isSearchable={true}
                        formatCreateLabel={() => ""}
                        placeholder={''}
                        noOptionsMessage={() => 'Keine Sportart gefunden'}
                        isLoading={loadingSports}
                        defaultValue={sport ? { value: sport, label: sport } : undefined}
                        value={sport ? { value: sport, label: sport } : undefined}
                        options={sports.map((value, index) => { return { value: value, label: value } })}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary: 'var(--bs-primary)',
                                primary75: 'color-mix(in srgb,var(--bs-primary),#0000 25%)',
                                primary50: 'color-mix(in srgb,var(--bs-primary),#0000 50%)',
                                primary25: 'color-mix(in srgb,var(--bs-primary),#0000 75%)'
                            },
                        })}
                        classNames={{
                            control: (state) =>
                                state.isFocused ? 'border-shadow-red' : '',
                        }}
                        styles={{
                            clearIndicator: ClearIndicatorStyles,
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                height: '50px',
                                borderRadius: '6px',
                                border: 'var(--bs-border-width) solid var(--bs-border-color)'
                            }),
                            menu: ({ width, ...css }) => ({
                                ...css,
                                width: 'fit-content',
                                minWidth: '100%',
                            }),
                        }}
                        onInputChange={value => {
                            if (value) {
                                setSport(value);
                            }
                        }}
                        components={{ ClearIndicator }}
                        onChange={value => {
                            setSport(value?.value ?? "");
                        }}
                    ></Creatable>
                </Form.Group>
                <Col sm={12} lg={6} xxl={4}>
                    <Row>
                        <Form.Group as={Col} className="club-postal-code-div form-group mb-2" sm={6} lg={5} >
                            <Form.Label className="club-postal-code-label form-label">Postleitzahl</Form.Label>
                            <FormInputField
                                type="text"
                                style={{ paddingRight: "45px" }}
                                setValue={(value) => {
                                    if (value?.length > 5 || (value && !value.match(/^[0-9]*$/g))) {
                                        setPostalCode(postalCode);
                                    } else {
                                        setPostalCode(value);
                                    }
                                }}
                                value={postalCode}
                                className="club-postal-code-input form-input"
                            />
                        </Form.Group>
                        <Form.Group as={Col} className="club-perimeter-div form-group mb-2" style={{ minWidth: "170px" }} sm={6} lg={7}>
                            <Form.Label className="club-perimeter-label form-label">Umkreis</Form.Label>
                            <ReactSelect className="club-perimeter-select select-font-size"
                                isClearable={true}
                                isSearchable={false}
                                placeholder={''}
                                defaultValue={perimeterOptions.find((value, index) => perimeter === value.value)}
                                options={perimeterOptions}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: 'var(--bs-primary)',
                                        primary75: 'color-mix(in srgb,var(--bs-primary),#0000 25%)',
                                        primary50: 'color-mix(in srgb,var(--bs-primary),#0000 50%)',
                                        primary25: 'color-mix(in srgb,var(--bs-primary),#0000 75%)'
                                    }
                                })}
                                classNames={{
                                    control: (state) =>
                                        state.isFocused ? 'border-shadow-red' : '',
                                }}
                                styles={{
                                    clearIndicator: ClearIndicatorStyles,
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        height: '50px',
                                        borderRadius: '6px',
                                        border: 'var(--bs-border-width) solid var(--bs-border-color)'
                                    }),
                                }}
                                components={{ ClearIndicator }}
                                onChange={value => setPerimeter(value?.value ?? "")}
                            ></ReactSelect>
                        </Form.Group>
                    </Row>
                </Col>
                <Form.Group as={Col} className="club-search-button-div form-group col mt-3 mt-sm-0 align-self-end mb-2"
                    style={{ display: "flex", flexWrap: "nowrap", justifyContent: "end" }} sm={12} lg={12} xxl={2} ref={btnContainerRef}>
                    <OverlayTrigger
                        placement={'top'}
                        container={btnContainerRef}
                        overlay={
                            <Tooltip>
                                Alle Filter löschen
                            </Tooltip>
                        }>
                        <Button className="club-search-button form-button svg-button" style={{ display: "flex", alignItems: 'center', marginRight: "20px" }} variant="outline-primary" type="button" onClick={event => {
                            setSearchParams(convertToSearchParams({}));
                            reset();
                            window.location.reload();
                        }}>
                            <FilterDeleteOutline />
                        </Button>

                    </OverlayTrigger>
                    <Button className="club-search-button form-button text-white" variant="primary" type="submit">Suchen</Button>
                </Form.Group>
            </Row>
        </Form>
    )
}

//convert inputs to a valid URLSearchParamsInit Object: Record<String, String | String[]>
const convertToSearchParams = (input: ClubSearchInput) => {
    const URLSearchParamsInit: URLSearchParamsInit = {};
    if (input.sportName) URLSearchParamsInit.sportName = input.sportName;
    if (input.postalCode) URLSearchParamsInit.postalCode = input.postalCode;
    if (input.perimeter) URLSearchParamsInit.perimeter = input.perimeter + "";
    if (input.clubName) URLSearchParamsInit.clubName = input.clubName;
    if (input.areaSearchType) URLSearchParamsInit.areaSearchType = input.areaSearchType;
    return URLSearchParamsInit;
}
