import React, { useState } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import { OverviewView } from "./components/OverviewView";
import { ClubDetailView } from "./pages/club/ClubDetailView";
import { TGDetailView } from "./pages/training-group/TGDetailView";

export interface AppProps {
    backendUrl: string
    runInitialSearch: boolean
}

interface AppTypeProps {
    type: 'TrainingGroup' | 'Club'
}

export const App : React.FC<AppProps & AppTypeProps> = (props: AppProps & AppTypeProps) => {
    const [firstEnter, setFirstEnter] = useState(true);

    const { runInitialSearch, ...others} = props;
    return (
        <React.StrictMode>
            <HashRouter>
                <Routes>
                <Route path={props.type === 'Club' ? "/training-group/:id" : "/:id"} element={<TGDetailView backendUrl={props.backendUrl} />} />
                {props.type === 'Club' && <Route path="/club/:id" element={<ClubDetailView backendUrl={props.backendUrl} />} />}
                <Route path="/" element={<OverviewView 
                {...others}
                runInitialSearch={runInitialSearch || !firstEnter}
                setFirstEnter={setFirstEnter}
                />} />
                </Routes>
            </HashRouter>
        </React.StrictMode>
    );
}